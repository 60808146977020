<template>
    <section class="mb-10">   
            <v-layout justify-space-around justify-center>
                <v-flex xs11 sm11 offset-sm0 class="mt-10">
                        <v-card flat tile color="" width="100%" class="transparent main-content-products">
                            <v-card-title primary-title class="justify-center">
                                    <h3 class="zamu-heading transact-heading product-heading team-heading">Our Team</h3><br />
                                    <v-card tile flat :color="`${zamuGrey}`" height="1" class="justify-center mt-5 transact-underline">
                                        <v-card-text class="transact-divider divider-purple"></v-card-text>
                                        <v-card-text class="transact-divider divider-yellow"> </v-card-text>
                                    </v-card>
                            </v-card-title>
                              <v-card-text align="center" justify="center">
                                <p class="product-text">
                                    We have about 20-40 members of the team including consultants. We highly invest in software engineering and other requisite skills to turn around the briefs we receive from our clients efficiently and effectively.
                                </p>
                            </v-card-text>
                          
                        </v-card>
                         <v-card tile flat color="">
                              <v-carousel  hide-delimiters>
                                <v-carousel-item
                                v-for="(item,i) in items"
                                :key="i"
                                :src="require(`../../../assets/images/${item.src}`)"
                                reverse-transition="fade-transition"
                                transition="fade-transition"
                                ></v-carousel-item>
                            </v-carousel>
                             

                        </v-card>
                </v-flex>
            </v-layout>
    </section>
</template>

<script>
import colorMixin from '@/mixins/colorMixin';
import coreMixin from '@/mixins/coreMixin';


export default {
    name: 'Team',
    mixins: [colorMixin, coreMixin],
    data () {
        return {
            team: null,
            currentPage: 1,
             items: [
                 {
                     src: 'team2-min.jpg'
                 },
                 {
                     src: 'team5-min.jpg'
                 },
                 {
                     src: 'team1-min.jpg'
                 },
                 {
                     src: 'team4-min.jpg'
                 },
                 {
                     src: 'team3-min.jpg'
                 }
               
                ],
        };
    },
    computed: {
        teamData () {
            return this.team !== null ? this.team.data : null;
        },
        pagination () {
            let data = null;
            if (this.team !== null) {
                 data = {
                    total: this.team.total,
                    perPage: this.team.perPage,
                    page: this.team.page,
                    lastPage: this.team.lastPage,
                };
            }
            return data;

        },

    },
   watch: {
        async currentPage () {
            await this.retrieveMembers();
        },
    },
    async mounted() {
        await this.retrieveMembers();
    },
    methods: {
        setPage(state) {
            let page = this.currentPage;
            if (state === 'next') {
                page  = page + 1;

            } else {
                page  = page - 1;
            }
            this.currentPage = page;

        },
        async retrieveMembers() {
                const payload = {
                page: this.currentPage,
                };

                const endpoint = `team/active`;

                const fullPayload = {
                    app: this.apiUrl,
                    params: payload,
                    endpoint,
                };

                try {
                    const response = await this.axiosGetRequest(fullPayload);
                    const team = response.status === 200 ? response.data : null;
                    this.team = team;
                    return team;
                } catch (error) {
                    return error;
                }
        },
    },

}
</script>

<style>

</style>